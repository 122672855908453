.circleSelfie {
  border-radius: 50% !important;
  width: 250px;
  height: 250px;
  object-fit: cover;
  border: 5px solid #958d9e;
}

.selfieWrapper {
  /* width: 100%; */
  height: auto;
}

.idCardWrapper {
  width: 100%;
  height: 350px;
}

.idCardSnapper {
  border-radius: 10%;
  width: 100%;
  height: 350px;
  object-fit: cover;
  border: 3px solid #958d9e;
}

.button-link {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: inline;
  margin: 0;
  padding: 0;
}

.button-link:hover,
.button-link:focus {
  text-decoration: none;
}

.btn-shutter {
  width: 80px;
  height: 80px;
  /* border: 2px solid #958d9e; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #2b7ec1;
}
.btn-shutter-icon {
  width: 40px;
  height: 40px;
  color: white;
}
.btn-faceChange {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
